import React, { useEffect, useCallback } from "react";
import * as faceapi from "@vladmandic/face-api";
import { Card, Stack, Container, Typography } from "@mui/material";
import AvatarIcon from "../Assets/images/avatar.png";
import faceMaskImg from "../Assets/images/face-mask.png";
import Btn from "../Components/Btn";
import RecognizedAudioSrc from "../Assets/audios/recognized.mp3";
import errorMp3 from "../Assets/audios/error.mp3";
import { MODELS_URL } from "../data";
import {
  DETECTION_DELAY,
  RESET_CACHE_FRAME_DELAY_AFTER_N_FRAMES,
  poseThresholdValue,
  faceSharpnessThresholdValue,
  faceBrightnessThresholdValue,
  useSettings,
} from "../contexts/SettingsContext";
import http from "../http";
import GlobalLoading from "../Components/loading";
import GlobalAlert from "../Components/alert";
import axios from "axios";

const RecognizedAudio = new Audio(RecognizedAudioSrc);
const errorAudio = new Audio(errorMp3);

const CHECK_IN_STATUS = {
  empty: 0,
  success: 1,
  warning: 2,
  error: 3,
  unrecognized: 4,
  closer: 5,
  farther: 6,
  holdStill: 7,
  center: 8,
};

const SUCCESS_COLOR = "#62f563";
const WARNING_COLOR = "#faca72";
const WAIT_COLOR = "#5186ec";
const ERROR_COLOR = "#f12d5a";
const DELAY_TIMER = 6;

let detectFunIntervalTimer = null;
let detectionTimeoutTimer = null;

export default function CustomerHome() {
  const superAdminVerified = true; // 当前账号是否过被club管理员审和已通过

  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [videoStarted, setVideoStarted] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [setp, setStep] = React.useState(0);
  const [checkInStatus, setCheckInStatus] = React.useState(
    CHECK_IN_STATUS.empty
  );
  const prevImgDescriptor = React.useRef(null);
  const samePersonCount = React.useRef(0);
  const videoRef = React.useRef();
  const canvasRef = React.useRef();

  const videoStream = React.useRef();
  const faceMask = React.useRef();
  const isFetching = React.useRef(false);
  const currentAvatar = React.useRef(null);
  const s3InfoRef = React.useRef(null);
  const CONFIGRef = React.useRef({});

  useEffect(() => {
    faceMask.current = new Image();
    faceMask.current.src = faceMaskImg;
  }, []);

  // 加载第三方插件（人脸识别）
  useEffect(() => {
    async function loadModels() {
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODELS_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODELS_URL),
      ])
        .then(() => setModelsLoaded(true))
        .catch((err) => {
          alert("Cannot load models. Please try again");
        });
    }
    loadModels();
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(detectionTimeoutTimer);
      clearTimeout(detectFunIntervalTimer);
      videoStream.current?.getTracks().forEach((t) => t.stop());
    };
  }, []);

  useEffect(() => {
    handleGetSetting();
  }, []);

  const handleGetSetting = async () => {
    try {
      GlobalLoading.show();
      const res = await http.get("/groups/settings");
      CONFIGRef.current = res;
      GlobalLoading.hide();
    } catch (e) {
      GlobalLoading.hide();
    }
  };

  // 获取上传凭证，url，key
  const handleGetS3Info = async () => {
    try {
      GlobalLoading.show();
      const res = await http.get(`/checkin/presignURL`);
      GlobalLoading.hide();
      s3InfoRef.current = res;
      handleVideoStart();
    } catch (e) {
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        msg: e,
      });
    }
  };

  const handleUploadImageToS3 = async (blob) => {
    try {
      const _s3Info = s3InfoRef.current;
      if (
        _s3Info.fields &&
        _s3Info.fields["Content-Type"] &&
        _s3Info.fields["key"] &&
        _s3Info.fields["AWSAccessKeyId"] &&
        _s3Info.fields["policy"] &&
        _s3Info.fields["signature"]
      ) {
        setStep(1);
        let payload = new FormData();
        payload.append("Content-Type", _s3Info.fields["Content-Type"]);
        payload.append("key", _s3Info.fields["key"]);
        payload.append("AWSAccessKeyId", _s3Info.fields["AWSAccessKeyId"]);
        payload.append("policy", _s3Info.fields["policy"]);
        payload.append("signature", _s3Info.fields["signature"]);
        payload.append("file", blob, "image.jpg");
        if (_s3Info.url) {
          GlobalLoading.show();
          const res = await axios.post(_s3Info.url, payload, {
            headers: {
              "Content-Type": "multipart/form-data;",
            },
          });
          GlobalLoading.hide();
          GlobalAlert.show({
            type: "info",
            msg: "Start Check in ! Please wait !",
          });
          handleCheckIn(_s3Info.fields["key"]);
        } else {
          GlobalAlert.error({
            msg: "Error! Please reload page !",
          });
        }
      }
    } catch (e) {
      console.log("ee==>>", e);
      setTimer(1);
      GlobalLoading.hide();
      GlobalAlert.show({
        type: "error",
        duration: 100000,
        msg:
          "Upload Error !" + e.code + " " + e.message + " " + e.response?.data,
      });
    }
  };

  const setTimer = (T = DELAY_TIMER) => {
    clearTimeout(detectionTimeoutTimer);
    detectionTimeoutTimer = setTimeout(async () => {
      setStep(0);
      setCheckInStatus(CHECK_IN_STATUS.empty);
      setUserInfo({});
      currentAvatar.current = null;
      await handleGetS3Info();
      isFetching.current = false;
    }, T * 1000);
  };

  const handleCheckIn = async (path) => {
    console.log("====start check in====");
    const CONFIG = CONFIGRef.current;
    try {
      const res = await http.post(`/checkin/?image_s3_path=${path}`);
      // 如果is_recognized=True且paramount_status=“G”或“NOT_APPLICABLE_FOR_NON_GYM”
      // 则显示消息: Welcome <first_name>
      if (res.is_recognized) {
        if (
          res.paramount_status === "G" ||
          res.paramount_status === "NOT_APPLICABLE_FOR_NON_GYM" ||
          res.paramount_status === "NOT_APPLICABLE_FOR_GUEST"
        ) {
          // success Welcome <first_name>  green
          setCheckInStatus(CHECK_IN_STATUS.success);
          RecognizedAudio.play();
          // DELAY_TIMER秒之后，清空
          setTimer(CONFIG.green_refresh_time || DELAY_TIMER);
        }

        if (
          !res.paramount_status &&
          res.status_message == "Customer is not approved by admin"
        ) {
          // Please visit the front desk.   yellow
          setCheckInStatus(CHECK_IN_STATUS.warning);
          errorAudio.play();
          // DELAY_TIMER秒之后，清空
          setTimer(CONFIG.red_refresh_time || DELAY_TIMER);
        }

        if (
          (res.paramount_status !== "G" &&
            res.paramount_status !== "NOT_APPLICABLE_FOR_NON_GYM" &&
            res.paramount_status !== "NOT_APPLICABLE_FOR_GUEST") ||
          res.status_message === "Member is not found in Paramount" ||
          res.paramount_status === "NOT_ALLOWED"
        ) {
          // Recognized, but <status_message>, Please visit the front desk. red
          setCheckInStatus(CHECK_IN_STATUS.error);
          errorAudio.play();
          // DELAY_TIMER秒之后，清空
          setTimer(CONFIG.red_refresh_time || DELAY_TIMER);
        }
      } else {
        // Unrecognized, please visit front desk  red
        setCheckInStatus(CHECK_IN_STATUS.unrecognized);
        errorAudio.play();
        // DELAY_TIMER秒之后，清空
        setTimer(CONFIG.red_refresh_time || DELAY_TIMER);
      }

      setStep(2);
      setUserInfo({
        ...res,
        pName: res.first_name,
        iName: currentAvatar.current,
        is_recognized: res.is_recognized,
        category: res.category,
        paramount_status: res.paramount_status,
      });
    } catch (e) {
      setStep(2);
      setUserInfo({
        status_message: `Customer unrecognized because ${e?.response?.data?.detail}, please try again.`,
      });
      setCheckInStatus(CHECK_IN_STATUS.error);
      errorAudio.play();
      setTimer(CONFIG.red_refresh_time || DELAY_TIMER);
    }
  };

  function stopCamera() {
    setUserInfo({});
    setVideoStarted(false);
    setCheckInStatus(CHECK_IN_STATUS.empty);
    videoRef.current = undefined;
    canvasRef.current = undefined;
    isFetching.current = false;
    clearCache();
    setStep(0);
    clearTimeout(detectFunIntervalTimer);
    clearTimeout(detectionTimeoutTimer);
    videoStream.current?.getTracks().forEach((t) => t.stop());
    window.history.go(0);
  }

  const handleVideoStart = async () => {
    try {
      setVideoStarted(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: {} });
      const video = videoRef.current;
      video.srcObject = stream;
      video.play();
      videoStream.current = stream;
    } catch (e) {}
  };

  async function startCamera() {
    try {
      handleGetS3Info();
    } catch (err) {
      console.error(err);
      alert("Unable to access camera.");
    }
  }

  /***
   * 需要做节流 ，正在识别中，就停止拍照，识别失败，重启应用
   * @param img
   * @returns {Promise<void>}
   */
  async function recognizePerson(img) {
    if (!isFetching.current) {
      isFetching.current = true;
      // 图片上传至S3
      const urlImg = img.toDataURL();
      currentAvatar.current = urlImg;
      img.toBlob(function (blob) {
        handleUploadImageToS3(blob);
      });
    }
  }

  /***
   * 画线，人脸方框
   * @param box
   * @param canvas
   * @param drawOptions
   */
  const drawFaceLineToCanvas = (box, canvas, drawOptions) => {
    // 使用Canvas API绘制边界框
    canvas.getContext("2d").strokeStyle = drawOptions.boxColor;
    canvas.getContext("2d").lineWidth = drawOptions.lineWidth;
    canvas.getContext("2d").strokeRect(box.x, box.y, box.width, box.height);

    // 添加文本
    canvas.getContext("2d").fillStyle = drawOptions.labelTextColor;
    canvas.getContext("2d").font = `${drawOptions.fontSize}px Arial`;
    const text = drawOptions.label;
    const textWidth = canvas.getContext("2d").measureText(text).width;
    const textX = box.x + (box.width - textWidth) / 2; // 文本水平居中
    const textY = box.y - drawOptions.fontSize; // 文本垂直居上
    canvas.getContext("2d").fillText(text, textX, textY);
  };

  /***
   * 获取人脸识别图片
   * @returns {Promise<void>}
   */
  async function detectFrame() {
    if (isFetching.current) {
      console.log("正在上传...");
      // 清除旧的人脸方框
      canvasRef.current?.getContext("2d").clearRect(0, 0, 300, 400);
      return;
    }

    if (!(canvasRef && canvasRef.current)) {
      return;
    }

    const detection = await faceapi.detectSingleFace(
      videoRef.current,
      new faceapi.TinyFaceDetectorOptions()
    );

    if (!detection) {
      return;
    }

    const videoHeight = videoRef.current?.height || 300;
    const videoWidth = videoRef.current?.width || 400;

    const resizedDetection = faceapi.resizeResults(detection, {
      width: videoWidth,
      height: videoHeight,
    });

    // 清除旧的人脸方框
    canvasRef.current.getContext("2d").clearRect(0, 0, videoWidth, videoHeight);

    // 红色人脸框
    const defaultDrawOptions = {
      labelTextColor: "red", // 标签文本颜色
      lineWidth: 4, // 边界框线宽
      label: ``,
      boxColor: "red", // 边界框颜色
      fontSize: 18, // 标签字体大小
    };

    drawFaceLineToCanvas(
      resizedDetection["_box"],
      canvasRef.current,
      defaultDrawOptions
    );

    const boxLeft = resizedDetection["_box"].left;
    const boxTop = resizedDetection["_box"].top;

    // 检测人脸宽高必须大于110px（太远了） 必须小于155（太近了）
    if (resizedDetection["_box"]["_height"] < 110) {
      console.log("== too small =="); // Please move closer
      setCheckInStatus(CHECK_IN_STATUS.closer);
      return false;
    }

    if (resizedDetection["_box"]["_height"] > 155) {
      console.log("== too far =="); // Too close, please move farther
      setCheckInStatus(CHECK_IN_STATUS.farther);
      return false;
    }

    // 检测人脸是否处于画布中心附近
    if (boxLeft < 90 || boxLeft > 170 || boxTop < 70 || boxTop > 150) {
      console.log("== not center ==");
      setCheckInStatus(CHECK_IN_STATUS.center);
      return false;
    }

    // 判断人脸识别系数是否大于0.6
    if (detection) {
      const confidenceScore = detection.score;
      if (confidenceScore > 0.6) {
        setCheckInStatus(CHECK_IN_STATUS.holdStill);

        // 绿色人脸框
        const drawOptions = {
          labelTextColor: "#03ff04", // 标签文本颜色
          lineWidth: 4, // 边界框线宽
          label: `${confidenceScore.toFixed(2)}`,
          boxColor: "#03ff04", // 边界框颜色
          fontSize: 18, // 标签字体大小
        };

        drawFaceLineToCanvas(
          resizedDetection["_box"],
          canvasRef.current,
          drawOptions
        );

        // 大于0.7的系数，然后进行上传图片识别
        const box = detection._box;
        // 从方框中提取脸部图片
        const faces = await faceapi.extractFaces(videoRef.current, [
          new faceapi.Rect(
            box._x - 100,
            box._y - 150,
            box._width + 200,
            box._height + 200
          ), // 矫正，原方法是从方框中提取图片，图片太小，导致识别不准确，现在加大图片
        ]);
        const currDetection = faces[0];
        recognizePerson(currDetection);
      } else {
        // 红色人脸框
        const drawOptions = {
          labelTextColor: "red", // 标签文本颜色
          lineWidth: 4, // 边界框线宽
          label: `${confidenceScore.toFixed(2)}`,
          boxColor: "red", // 边界框颜色
          fontSize: 18, // 标签字体大小
        };

        drawFaceLineToCanvas(
          resizedDetection["_box"],
          canvasRef.current,
          drawOptions
        );
      }
    } else {
      console.log("confidenceScore. no");
    }
  }

  function clearCache() {
    prevImgDescriptor.current = null;
    samePersonCount.current = 0;
  }

  const handleVideoPlay = useCallback(() => {
    try {
      setTimeout(() => {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );

        faceapi.matchDimensions(canvasRef.current, {
          width: videoRef.current?.width || 400,
          height: videoRef.current?.height || 300,
        });
        function detectFunInterval() {
          clearTimeout(detectFunIntervalTimer);
          detectFunIntervalTimer = setTimeout(() => {
            detectFrame();
            detectFunInterval();
          }, 200);
        }
        detectFunInterval();
      }, 0);
    } catch (e) {
      console.log("handleVideoPlay e==>>", e);
    }
  }, []);

  if (!superAdminVerified) {
    return (
      <Container>
        <Card sx={{ p: 5, m: 5 }}>
          <h2 style={{ fontWeight: "normal" }}>
            Your account is not verified by <b>superadmin</b> yet. Once
            superadmin approves, you will be able to use your account
          </h2>
          <p>Thank you</p>
        </Card>
      </Container>
    );
  }

  if (!videoStarted) {
    return (
      <Container maxWidth="md" sx={{ m: 10, mt: 20, margin: "auto" }}>
        <div style={{ textAlign: "center" }}>
          <Card
            style={{
              marginBottom: 30,
              border: "1px solid #ddd",
              padding: "60px 30px",
              borderRadius: 6,
            }}
          >
            <Typography variant="h6" style={{ color: "#0B0B45" }}>
              {modelsLoaded && "Please start your camera."}
              {!modelsLoaded && "Loading data, Please wait!"}
            </Typography>
          </Card>
          <Btn
            onClick={startCamera}
            disabled={!modelsLoaded}
            sx={{
              my: 3,
              width: 300,
              height: 50,
              borderRadius: "25px",
            }}
          >
            {modelsLoaded ? "Start camera" : "Loading"}
          </Btn>
        </div>
      </Container>
    );
  }

  const [welcomeMsg, backgroundColor] = getUserMsg(userInfo, checkInStatus);
  return (
    <Container maxWidth="md" sx={{ m: 10, mt: 2, margin: "auto" }}>
      <Stack alignItems="center">
        {/* camera */}
        <h1 style={{ textAlign: "center" }}>Requires an unobstructed face</h1>
        <div style={{ position: "relative" }}>
          <video
            ref={videoRef}
            width={400}
            height={300}
            onLoadedMetadata={handleVideoPlay}
            style={{
              transform: "rotateY(0deg)",
              borderRadius: "15px",
            }}
          ></video>
          <img
            src={faceMaskImg}
            alt=""
            style={{
              width: 400,
              height: 300,
              position: "absolute",
              left: 0,
              borderRadius: "15px",
            }}
          />

          <canvas
            ref={canvasRef}
            style={{
              width: 400,
              height: 300,
              position: "absolute",
              left: 0,
              borderRadius: "15px",
            }}
          />
        </div>

        <Stack
          id="result-panel"
          direction="row"
          alignItems="center"
          sx={{
            m: 2,
            p: "30px 80px 30px 40px",
            marginTop: 1,
            width: "640px",
            boxShadow: 1,
            borderRadius: "10px",
            background: backgroundColor,
          }}
        >
          <div
            style={{
              position: "relative",
              display: "block",
              width: 80,
              height: 80,
              borderRadius: "15px",
            }}
          >
            <img
              src={userInfo["iName"] ? userInfo["iName"] : AvatarIcon}
              alt="customer"
              style={{
                width: "inherit",
                height: "inherit",
                borderRadius: "inherit",
              }}
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>{welcomeMsg}</div>
        </Stack>

        <Btn
          onClick={stopCamera}
          sx={{
            mt: 2,
            mb: 3,
            width: 300,
            height: 50,
          }}
        >
          Stop camera
        </Btn>
      </Stack>
    </Container>
  );
}

function getUserMsg(userInfo, status) {
  if (status === CHECK_IN_STATUS.empty) {
    return [
      <div>
        <Typography variant="h4">
          Please hold still for a few seconds while we verify your identity
        </Typography>
      </div>,
      "none",
    ];
  }

  if (status === CHECK_IN_STATUS.success) {
    return [
      <div>
        <Typography variant="h3" sx={{ color: "green" }}>
          Welcome
        </Typography>
        <Typography variant="h3">{userInfo["pName"]}</Typography>
      </div>,
      SUCCESS_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.warning) {
    return [
      <div>
        <Typography variant="h3">Please visit the front desk</Typography>
      </div>,
      WARNING_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.center) {
    return [
      <div>
        <Typography variant="h3">Please keep your face centered</Typography>
      </div>,
      WARNING_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.closer) {
    return [
      <div>
        <Typography variant="h3">Please move closer</Typography>
      </div>,
      WARNING_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.holdStill) {
    return [
      <div style={{ width: "100%" }}>
        <Typography
          variant="h3"
          style={{
            width: "100%",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Please hold still
        </Typography>
      </div>,
      WAIT_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.farther) {
    return [
      <div>
        <Typography variant="h3">Too close, please move farther</Typography>
      </div>,
      WARNING_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.error) {
    return [
      <div>
        <Typography variant="h4">
          {`Recognized, but ${userInfo.status_message}, Please visit the front desk`}
        </Typography>
      </div>,
      ERROR_COLOR,
    ];
  }

  if (status === CHECK_IN_STATUS.unrecognized) {
    return [
      <div>
        <Typography variant="h3">
          Unrecognized, please visit front desk
        </Typography>
      </div>,
      ERROR_COLOR,
    ];
  }
}
